:root{
    --primary: #2b00ff;
    --secondary: #f0740f;
    --dark: #020b2c;
    --light: #f3f3f3;
    --normal_purple_light: #8080ff;
    --normal_purple_dark: #8025da;
    --normal_green_light: #80dada;
    --normal_green_dark: #36c9c9;
    --normal_blue_light: #2580da;
    --normal_blue_dark: #3636c9;
    --normal_pink_light: #da80da;
    --normal_pink_dark: #c936c9;
    --normal_beige: #c9c9c9;
}

*
{
    margin: 0;
    padding: 0;
}

html
{
    background: #020b2c
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


body{
    font-family:'Cabin', sans-serif;
    width: 100%;
    min-height: 100vh;
    color: var(--light);
    line-height: 1.8;

}



.section
{
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;
    padding-left: 10%;
    padding-right: 10%;
}

.links{
    color:var(--light);
    
}
.first-section {
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0));
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37); /* this adds the "card" effect */
    padding: 18rem 0 0;
    width: 100%;
    border-radius: 0.3rem;
    overflow: hidden;
    transition: transform 500ms ease;
}

.first-section:hover{
    transform: scale(1.05);
}


.first-section p{
    font-size: 20px
}


.first-section-content{
    --padding: 1.5rem;
    padding: var(--padding);
    background: linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.37)
    );
    transform: translateY(46%);
    transition: transform 700ms ease;
}

.first-section:hover .first-section-content{
    transform: translateY(0%);

}

.first-section-title{
    position: relative;
    width: max-content;
    max-width: 100%;
    text-transform: uppercase;
    font-size:50px ;
}

.first-section-title::after{
    content: "";
    position:absolute;
    height: 3px;
    left: calc(-1 * var(--padding));
    bottom: -2px;
    width: calc(100% + var(--padding));
    background: var(--normal_green_light);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 500ms ease;
}

.first-section:hover .first-section-title::after {
    transform: scaleX(1);
}



* {
    box-sizing: border-box;
  }



.container h1{
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0));
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37); /* this adds the "card" effect */
    display: flex;
    align-items: center;
    height: 20vh;
    padding-left: 1.5rem;
    position: relative;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 0.3rem;
    overflow: hidden;
    transition: transform 500ms ease;

}

.container h1:hover {
    transform: scale(1.02);
}
  
.column {
    float: left;
    width: 25%;
    padding: 0 10px;
}
  
.row {margin: 0 -5px;}
  
.row:after {
    content: "";
    display: table;
    clear: both;
}
  
.card {
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0));
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37); /* this adds the "card" effect */
    padding: 10rem 0 0;
    max-width: 35ch;
    border-radius: 0.3rem;
    overflow: hidden;
    transition: transform 500ms ease;
}

.card:hover{
    transform: scale(1.05);
}


.card p{
    font-size: 12px
}


.card-content{
    --padding: 1.5rem;
    padding: var(--padding);
    background: linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.37)
    );
    transform: translateY(65%);
    transition: transform 700ms ease;
}

.card:hover .card-content{
    transform: translateY(0%);

}

.card-title{
    position: relative;
    width: max-content;
    max-width: 100%;
    font-size:24px ;
}

.card-title::after{
    content: "";
    position:absolute;
    height: 3px;
    left: calc(-1 * var(--padding));
    bottom: -2px;
    width: calc(100% + var(--padding));
    background: var(--normal_green_light);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 500ms ease;
}

.card:hover .card-title::after {
    transform: scaleX(1);
}
  
  /* Responsive columns - one column layout (vertical) on small screens */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }